import { ButtonLink } from 'components/Button'
import { useCurrentGroup } from 'lib/GroupContext'
import { authUrl } from 'lib/login'
import { allowSignUpGroupUser } from 'lib/signUp'
import { useCurrentUser } from 'lib/UserContext'
import Link from 'next/link'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import Skeleton from 'react-loading-skeleton'

import { GroupProp } from './Hero'

const HeroCTAButton: FC<GroupProp> = ({ group, loading }) => {
  const { formatMessage, locale } = useIntl()
  const { currentUser } = useCurrentUser()
  const { currentGroup } = useCurrentGroup()

  if (loading) {
    return <Skeleton borderRadius="999px" width="12rem" className="px-8 h-12" />
  }

  const showSignUp = group && allowSignUpGroupUser(currentGroup, currentUser)

  const groupButtonStyle = {
    backgroundColor: group?.styles?.accentColor,
    color: group?.styles?.accentTextColor,
  }

  if (!currentUser && !showSignUp) {
    return null
  }

  const providerUrl = authUrl(currentGroup)
  const signUpUrl = providerUrl || `/${locale}/sign-up`

  return (
    <Link href={currentUser ? `/${locale}/personal` : signUpUrl} passHref>
      <ButtonLink
        testId="hero-cta-button"
        className="flex-0 w-1/2 md:w-2/3 text-sm lg:text-base"
        style={groupButtonStyle}
      >
        {formatMessage({
          id: currentUser ? 'group.dashboard' : 'group.getStarted',
        })}
      </ButtonLink>
    </Link>
  )
}

export default HeroCTAButton
